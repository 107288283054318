.awssld{
    --cube-animation-duration: 675ms;
    --cube-animation-perspective: 1800px;
    --cube-animation-ease-in: cubic-bezier(0.8, 0, 1, 0.8);
    --cube-animation-ease-out: cubic-bezier(0, 0.2, 0.2, 1);
    --cube-translateZ-distance: -225px;

    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;

    --organic-arrow-thickness: 10px;
    --organic-arrow-height: 40px;
    --slider-height-percentage: 60%;
    --loader-bar-color: #745B2F;
    --loader-bar-height: 6px;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-opacity: 0.5;
    --control-button-hover-opacity: 0.75;
    --control-button-background: transparent;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 575ms;
    --organic-arrow-color: #4524BC;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #8E76E5;
    --control-bullet-active-color: #4524BC;
    --content-background-color: #2f2f2f;
}