:root{
  --fontText: 'Josefin Sans', sans-serif;
  --fontTitle: 'Lora', sans-serif;
  --colorText: #363636;
  --colorPrimary: #4524BC;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--fontText);
  color: var(--colorText);
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}
h1,h2,h3{
  margin: 0;
  font-family: var(--fontTitle);
}
ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
img{
  max-width: 100%;
  height: auto;
}
button{
  appearance: none;
  border: none;
}
h1{
  font-size: 3.2rem;
}
h2{
  font-size: 2.8rem;
}
h3{
  font-size: 2.4rem;
}
p{
  margin: 1rem 0;
  font-size: 1.6rem;
}
label{
  font-size: 1.6rem;
}
input[type="submit"], 
button{
  text-decoration: none;
  outline: none;
  appearance: none;
  border: none;
  background-color: transparent;
}
input:focus{
  outline: none;
  border: none;
}



/*================================================
Feedback Area CSS
=================================================*/
.feedback-slides {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
  .feedback-slides {
    max-width: 750px;
  }
}
.feedback-slides .owl-nav {
  margin: 0 !important;
  display: none;
}
@media only screen and (min-width: 768px) {
  .feedback-slides .owl-nav {
    display: block;
  }
}

.feedback-slides .owl-nav .owl-prev {
  position: absolute;
  left: -40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  padding: 0 !important;
  border-radius: 30px 0px 0px 30px !important;
  margin: 0 !important;
  background: #fff!important;
  color: var(--colorPrimary)!important;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 20%);
}

.feedback-slides .owl-nav .owl-prev i {
  padding-left: 2px;
}

.feedback-slides .owl-nav .owl-prev:hover {
  transform: translateY(-50%) scale(1.05);
}

.feedback-slides .owl-nav .owl-next {
  position: absolute;
  right: -40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  padding: 0 !important;
  border-radius: 0 30px 30px 0 !important;
  margin: 0 !important;
  background: #fff!important;
  color: var(--colorPrimary)!important;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 20%);
}

.feedback-slides .owl-nav .owl-next i {
  padding-right: 2px;
}

.feedback-slides .owl-nav .owl-next:hover {
  transform: translateY(-50%) scale(1.05);
}